import React,{useEffect} from 'react'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

export default function RoadMap() {

  return (
    <div className='RoadMap'>
      <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
      <h1>Road Map Activations</h1>
      <p>
      We’re here to stay!<br/><br/>
We have set up a road map to track progress and set goals. Once we hit a milestone
percentage, we work on bringing that milestone goal to fruition.
      </p>
      {/* <span>
        <div className='RoadMapLeft'>
      <div className="MapCon">
        <div className="MapLeft">
          <h2>10%</h2>
        </div>
        <div className="MapCenter">
          <div className="MapInd" />
          <div className="MapLine" />
        </div>
        <div className="MapRight">
          <h3>Pay back investors</h3>
        </div>
      </div>
      <div className="MapCon">
        <div className="MapLeft">
          <h2>20%</h2>
        </div>
        <div className="MapCenter">
          <div className="MapInd" />
          <div className="MapLine" />
        </div>
        <div className="MapRight">
          <h3>Place Donation in Escrow</h3>
        </div>
      </div>
      <div className="MapCon">
        <div className="MapLeft">
          <h2>30%</h2>
        </div>
        <div className="MapCenter">
          <div className="MapInd" />
          <div className="MapLine" />
        </div>
        <div className="MapRight">
          <h3>Hire Artist Full Time</h3>
        </div>
      </div>
      <div className="MapCon">
        <div className="MapLeft">
          <h2>40%</h2>
        </div>
        <div className="MapCenter">
          <div className="MapInd" />
          <div className="MapLine" />
        </div>
        <div className="MapRight">
          <h3>Hire Programer Full Time</h3>
        </div>
      </div>
      <div className="MapCon">
        <div className="MapLeft">
          <h2>50%</h2>
        </div>
        <div className="MapCenter">
          <div className="MapInd" />
          <div className="MapLine" />
        </div>
        <div className="MapRight">
          <h3>Release Company Roadmap with Future Projects</h3>
        </div>
      </div>
      <div className="MapCon">
        <div className="MapLeft">
          <h2>60%</h2>
        </div>
        <div className="MapCenter">
          <div className="MapInd" />
          <div className="MapLine" />
        </div>
        <div className="MapRight">
          <h3>Commence Project 2 20202(10101 to be Air Dropped to NFT Holders)</h3>
        </div>
      </div>
      <div className="MapCon">
        <div className="MapLeft">
          <h2>70%</h2>
        </div>
        <div className="MapCenter">
          <div className="MapInd" />
          <div className="MapLine" />
        </div>
        <div className="MapRight">
          <h3>Open MetaVerse Gallery and Merch Shop with T’s, Hoodies, Hats, and Stickers of your NFT and Logo</h3>
        </div>
      </div>
      <div className="MapCon">
        <div className="MapLeft">
          <h2>80%</h2>
        </div>
        <div className="MapCenter">
          <div className="MapInd" />
          <div className="MapLine" />
        </div>
        <div className="MapRight">
          <h3>Start Creation the Message Board(Digital Remembrance Quilt)</h3>
        </div>
      </div>
      <div className="MapCon">
        <div className="MapLeft">
          <h2>90%</h2>
        </div>
        <div className="MapCenter">
          <div className="MapInd" />
          <div className="MapLine" />
        </div>
        <div className="MapRight">
          <h3>Break Ground on Digital Doggie Country Club(The Lost Dogs Club) NFT Required for Membership! </h3>
        </div>
      </div>
      <div className="MapCon">
        <div className="MapLeft">
          <h2>100%</h2>
        </div>
        <div className="MapCenter">
          <div className="MapInd" />
          <div className="MapLine" />
        </div>
        <div className="MapRight">
          <h3>Fulfill Donation Promise of 10% of Total Earn</h3>
        </div>
      </div>
      </div>
      </span> */}
      {/* <div className='RoadMapRight'>
      <img src={StoryImg}/>
      </div> */}
      <VerticalTimeline >
      <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#fff", color: "#000" }}
          iconStyle={{background: `#bf1b25` , color: "#fff" }}//#3d1b7f
          // icon={<AiOutlineTwitter style={{cursor:"pointer"}} color={'#fff'} size={45}/>}
        >
          <h3>
          10% 
          Pay Back Investors
          </h3>
        </VerticalTimelineElement>
      <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#fff", color: "#000" }}
          iconStyle={{ background: "#bf1b25", color: "#609632" }}
          // icon={<WorkIcon />}
        >
          <h3>
          20%
          Place Donation in Escrow
          </h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#fff", color: "#fff"}}
          iconStyle={{ background: "#bf1b25", color: "#fff" }}
          // icon={<WorkIcon />}
        >
          <h3>30%
          Hire Artist Full Time
          </h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#fff", color: "#fff", }}
          iconStyle={{ background: "#bf1b25", color: "#fff" }}
          // icon={<WorkIcon />}
        >
          <h3>40%
          Hire Full-time Programers
          </h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#fff", color: "#fff", }}
          iconStyle={{ background: "#bf1b25", color: "#fff" }}
          // icon={<WorkIcon />}
        >
          <h3>50%
          Release Company Roadmap with Future Projects
          </h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#fff", color: "#fff", }}
          iconStyle={{ background: "#bf1b25", color: "#fff" }}
          // icon={<WorkIcon />}
        >
          <h3>60%
          Commence NFT Project 2
          </h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#fff", color: "#fff", }}
          iconStyle={{ background: "#bf1b25", color: "#fff" }}
          // icon={<WorkIcon />}
        >
          <h3>70%
          Open Merch Shop 
          </h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#fff", color: "#fff", }}
          iconStyle={{ background: "#bf1b25", color: "#fff" }}
          // icon={<WorkIcon />}
        >
          <h3>80%
          Start Creating the Message Board and Meta Gallery 
          </h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#fff", color: "#fff", }}
          iconStyle={{ background: "#bf1b25", color: "#fff" }}
          // icon={<WorkIcon />}
        >
          <h3>90%
          Break Ground on Digital The Lost Dogs Country Club
          </h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#fff", color: "#fff", }}
          iconStyle={{ background: "#bf1b25", color: "#fff" }}
          // icon={<WorkIcon />}
        >
          <h3>100%
          Fulfill Donation Promise of 10% of Total Earnings 
          </h3>
        </VerticalTimelineElement>
      </VerticalTimeline>
      </div>
    </div>
  )
}
