import React,{useEffect} from 'react'
import StoryImg from "../assets/LostPaper.png";

export default function Paper() {

  return (
    <div className='Paper' id="section3">
        <h1>The Lost Paper</h1>
      <span>
      <div className='PaperRight'/>
        <div className='PaperLeft'>
        <p>
        The Lost Dogs is a revolutionary NFT project that will forever change the way the world views
NFTs and their ability to create positive change in the world. We plan to continue our
philanthropic work throughout every project we create. We also have plans to build off the
success of our first project, with planned partnerships, airdrops, and other innovative boundary
pushing projects.<br/><br/>
We ultimately seek to bridge the gap between Web3, blockchain, and the real world
marketplace. We want to help companies develop their NFT and cryptocurrency projects custom
tailored to fit the needs of their customers. We are creating a CryptoCurrency, NFT Marketplace,
Staking Pool, Exchange and Investing Firm.<br/><br/>
The Lost Dogs is a Collection of 10,101 The Lost Dogs NFTs lost somewhere on the Ethereum
Blockchain. Your NFT will also be your membership card, allowing you access to such features
as the message board and The Lost Dogs Country Club. We will begin construction of these
features as they are unlocked on the community roadmap.<br/><br/>
<b>The Problem</b><br/>
There are too many NFT Projects trying to do the same thing (nothing)..<br/><br/>
There is too much instability in the space, which can lead to rug pulls, scams, and mistrust.<br/><br/>
Too many lost projects<br/><br/>
Too many lost dogs<br/><br/>
<b>The Solution</b><br/>
Our mission is to offer trust, clarity, continuity, and new applications for this emerging
technology. We want to synergize Web3, Cryptocurrency, NFTs, the real world marketplace and
consumers.<br/><br/>
<b>The Lost Projects:</b> We keep it real, transparent, open, and trustworthy. We stand by our work
and our mission.<br/><br/>
<b>The Lost Dogs:</b> We generate awareness, and offer the ability to directly impact by donating
10% of the total project to the ASPCA; this will have a sizable real world NFT impact.<br/><br/>
<b>Distribution</b><br/>
10,101 The Lost Dogs NFTs will be available two different ways. We will have a Presale and a
Public Sale. We will release 2,000 whitelist spots which will be available through our Discord,
Instagram, Telegram and Twitter.<br/><br/>
Purchasing The Lost Dogs NFT costs<br/>0.10 ETH PreSale Limit 5 per wallet<br/>0.15 ETH Public Sale Limit 10 per wallet<br/><br/>
51 The Lost Dogs NFTs are being withheld from this sale and will be used for giveaways,
rewards and for the Creator’s The Lost Dogs Memberships.<br/><br/>
<b>The Details</b><br/>
Each The Lost Dogs is one of a kind and computer generated from over 201 possible traits,
including Head, Neck, Eyes, Ears, Glasses, Shirt, Mouth, Skin Color, and Background. All dogs
are unique; some attributes are more unique than others (80’s themed)<br/><br/>
The Lost Dogs are stored as ERC-721 Tokens on the Ethereum blockchain and hosted on IPFS (<a target={'_blank'}>See Record</a>)<br/><br/>
To access the members only areas such as the Message Board and The Lost Dogs Country
Club, The Lost Dogs hodlers will sign in with their wallet containing their The Lost Dogs NFT.
        </p>
        </div>
        <div className='PaperRight'>
          <img src={StoryImg}/>
        </div>
      </span>
    </div>
  )
}
