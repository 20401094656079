import React,{useEffect} from 'react'

export default function Club({myRef}) {

  return (
    <div className='Gallery' ref={myRef}>
      <span>
      <h1>The Lost Dogs Metaverse Country Club</h1>
      <h2>When you buy a The Lost Dogs NFT, you are gaining access to The Lost Dogs Country
Club,This is the first ever Metaverse Country Club where you can golf, swim, shoot, and play
tennis as your The Lost Dogs avatar. NFT required for membership!
</h2>
      {/* <h2>Coming Soon</h2> */}
      </span>
    </div>
  )
}
