import React,{useEffect, useState} from 'react'
import StoryImg from "../assets/Mint.png";
import { useDispatch, useSelector } from "react-redux";
import {connect, updateTotalSupply} from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import { handler } from "../api/index";
import Web3 from "web3";
import { getPresale } from "../components/getPresaleStatus";
import { getPause } from "../components/getPauseStatus";
import { getPublic } from "../components/getPublicStatus";

export default function Mint() {

  const { ethereum } = window;
  const web3 = new Web3(ethereum);
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    PRE_WEI_COST: 0,
    PRE_DISPLAY_COST: 0,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .publicMint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        try {
          var lines = err?.message.split("\n");
          const tx = lines[12].slice(22, 88);
          getRevertReason(tx);
          setClaimingNft(false);
        } catch (err) {
          console.log(err);
          setFeedback("The transaction has been cancelled!");
          setClaimingNft(false);
        }
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimPreNFTs = () => {
    let cost = CONFIG.PRE_WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .whitelistMint(mintAmount, proof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        try {
          var lines = err?.message.split("\n");
          const tx = lines[12].slice(22, 88);
          getRevertReason(tx);
          setClaimingNft(false);
        } catch (err) {
          console.log(err);
          setFeedback("The transaction has been cancelled!");
          setClaimingNft(false);
        }
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getRevertReason = async (txHash) => {
    const val = await web3.eth.getTransaction(txHash);
    try {
      var err = await web3.eth.call(val);
      console.log("dsd", err);
    } catch (err) {
      const firstLine = err?.message.match(/^.*$/m)[0];
      const errMsg = firstLine.replace("execution reverted: ", "");
      setFeedback(errMsg);
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    const limit = preSale? 3 : data.currentTokenID<=2332? 1 : 6;
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > limit) {
      newMintAmount = limit;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const UpdateTS = async () => {
    await updateTotalSupply(dispatch);
  }

  useEffect(() => {
    UpdateTS()
  }, [CONFIG.CONTRACT_ADDRESS]);

  const [proof, setProof] = useState(null);

  useEffect(() => {
    handler({ event: blockchain.account, setVal: setProof });
  }, [blockchain.account]);

  const [pause, setPause] = useState();

  useEffect(() => {
    getPause().then((val) => {
      setPause(val)
      console.log("Pause", val);
    });
  }, []);
  
  const [preSale, setPreSale] = useState();

  useEffect(() => {
    getPresale().then((val) => {
      setPreSale(val)
      console.log("Preval", val)
    });
  }, []);

  const [publics, setPublic] = useState();

  useEffect(() => {
    getPublic().then((val) => {
      setPublic(val)
      console.log("Public", val)
    });
  }, []);
  
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setInterval(() => {
      setVisible(true)
    }, 2500);
  }, []);

  return (
    <div className='Mint' id="section2">
      <span>
      <div className='MintLeft'>
      <img src={StoryImg}/>
      </div>
      <div className='MintRight'>
        <h1>Find a Dog</h1>
        <p>Mint a Lost dog, so they can be Found!</p>
        <button>Minting Starts Soon</button>
        {/* {pause?
        <div className="HeadMint">
          <h1>The sale is temporarily paused!</h1>
        </div>
      :
      <>
      {preSale?
          <div className="HeadMint">
            <p>{blockchain.account != null && proof == null? "Minting address is not on whitelist" : null}</p>
            {blockchain.account === "" ||
            blockchain.smartContract === null || proof == null? (
              <>
                {window.ethereum ? (
                <button
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                }}
              >
                Connect Wallet
              </button>
              ) : (
              <button onClick={()=>window.open("https://metamask.app.link/dapp/minttest.thelostdogs.io/")}>
               Connect Wallet
                </button>
              )}
                {blockchain.errorMsg !== "" ? (
                  <>
                    <p>{blockchain.errorMsg}</p>
                  </>
                ) : null}
              </>
            ) : (
              <>
                <p>{feedback}</p>
                <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        decrementMintAmount();
                      }}
                    >
                      -
                    </button>
                    <h2
                      style={{ marginLeft: 20, marginRight: 20, marginTop: 15 }}
                    >
                      {mintAmount}
                    </h2>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        incrementMintAmount();
                      }}
                    >
                      +
                    </button>
                  </div>
                <button
                  style={{marginTop:20}}
                  onClick={(e) => {
                    e.preventDefault();
                    claimPreNFTs()
                    getData();
                  }}
                >
                  {claimingNft ? "Minting" : "Mint"}
                </button>
              </>
            )}
          </div>
          :publics?
          <div className="HeadMint">
            {blockchain.account === "" || blockchain.smartContract === null ? (
              <>
                {window.ethereum ? (
                <button
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                }}
              >
                Connect Wallet
              </button>
              ) : (
              <button onClick={()=>window.open("https://metamask.app.link/dapp/minttest.thelostdogs.io/")}>
               Connect Wallet
                </button>
              )}
                {blockchain.errorMsg !== "" ? (
                  <>
                    <p>{blockchain.errorMsg}</p>
                  </>
                ) : null}
              </>
            ) : (
              <>
                <p>{feedback}</p> 
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        decrementMintAmount();
                      }}
                    >
                      -
                    </button>
                    <h2
                      style={{ marginLeft: 20, marginRight: 20, marginTop: 15 }}
                    >
                      {mintAmount}
                    </h2>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        incrementMintAmount();
                      }}
                    >
                      +
                    </button>
                  </div>
                <button
                  style={{marginTop:20}}
                  onClick={(e) => {
                    e.preventDefault();
                    claimNFTs();
                    getData();
                  }}
                >
                  {claimingNft ? "Minting" : "Mint"}
                </button>
              </>
            )}
          </div>
          :
          <div className="HeadMint">
            {window.ethereum ? (
              visible ? <p style={{marginTop:30}}>Change Network to {CONFIG.NETWORK.NAME} and reload the page</p> : null
              ) : (
              <button onClick={()=>window.open("https://metamask.app.link/dapp/minttest.thelostdogs.io/")}>
               Connect Wallet
                </button>
              )}
          </div>
      }
      </>
      } */}
      </div>
      </span>
    </div>
  )
}
