import React,{useEffect} from 'react'
import StoryImg from "../assets/hidden.jpg";

export default function Team({myRef}) {

  return (
    <div className='Team'>
        <h1>Team</h1>
        <p>The Lost Dogs was created by three friends on a journey to make some kool dogs and shake up
        the NFT and Crypto Space.
        </p>
        <div className='TeamCon'>
        <span>
            <div className='TeamLeft'>
            <img src={StoryImg}/>
            </div>
            <div className='TeamRight'>
                <h2 className='TeamText'>
                    <b>DGM IV</b><br/><br/>
                    Finder of Ghost, Kills People Digitally in his free time, long time crypto enthusiast and
                    NFT adopter. Dan has a Business Management degree from St Joe’s University and cut his
                    teeth running his family car dealership. Dan has put down his roots with the creation of Plant
                    Care Products, and brings his unique entrepreneurial skills and visions to life in the NFT and
                    crypto space.
                </h2>
            </div>
        </span>
        <span>
            <div className='TeamLeft1'>
                <h2 className='TeamText'>
                    <b>Wattie2hottie</b><br/><br/>
                    Has worked in the financial services for 25 years, in various positions. In 2012,
                    he started his own registered investment advisory firm to provide institutional investing solutions
                    to successful individuals. As an early crypto adopter, Mark has found a niche in investing and
                    growing wealth in the crypto space.
                </h2>
            </div>
            <div className='TeamRight1'>
            <img src={StoryImg}/>
            </div>
        </span>
        {/* <span>
            <div className='TeamLeft'>
            <img src={StoryImg}/>
            </div>
            <div className='TeamRight'>
                <h2 className='TeamText'>
                    <b>Third Amego</b><br/><br/>
                    MIA
                </h2>
            </div>
        </span> */}
        <span>
            <div className='TeamRight'>
            <img src={StoryImg}/>
            </div>
            <div className='TeamLeft'>
                <h2 className='TeamText'>
                    <b>Diego</b><br/><br/>
                    Diego is a digital artist & illustrator based in Barcelona, SPA. Fascinated with the digital
painting process his skills range from editorial illustration, character design, custom portraits,
movie posters and animation/motion graphics.
                </h2>
            </div>
        </span>
        <span>
            <div className='TeamLeft1'>
                <h2 className='TeamText'>
                    <b>Umesh aka Clueless_Nidget</b><br/><br/>
                    An Aerospace engineer who loves trekking and traveling, Umesh is a low gas fee smart contract expert, a blockchain enthusiast innovating the future today. He’s a forward thinking adventurer, a  detail obsessed programming professional. With the skills to bring the project to life, he deftly crafted the infrastructure for The Lost Dogs to stand on.
                </h2>
            </div>
            <div className='TeamRight1'>
            <img src={StoryImg}/>
            </div>
        </span>
        </div>


        {/*-----------------------*/}
        <div className='TeamCon1'>
        <span>
            <div className='TeamLeft'>
            <img src={StoryImg}/>
            </div>
            <div className='TeamRight'>
                <h2 >
                <b>DGM IV</b><br/><br/>
                    Finder of Ghost, Kills People Digitally in his free time, long time crypto enthusiast and
NFT adopter. Dan has a Business Management degree from St Joe’s University and cut his
teeth running his family car dealership. Dan has put down his roots with the creation of Plant
Care Products, and brings his unique entrepreneurial skills and visions to life in the NFT and
crypto space.
                </h2>
            </div>
        </span>
        <span>
            <div className='TeamLeft1'>
            <img src={StoryImg}/>
            </div>
            <div className='TeamRight1'>
                <h2 className='TeamText'>
                <b>Wattie2hottie</b><br/><br/>
                    Has worked in the financial services for 25 years, in various positions. In 2012,
                    he started his own registered investment advisory firm to provide institutional investing solutions
                    to successful individuals. As an early crypto adopter, Mark has found a niche in investing and
                    growing wealth in the crypto space.
                </h2>
            </div>
        </span>
        {/* <span>
            <div className='TeamLeft'>
            <img src={StoryImg}/>
            </div>
            <div className='TeamRight'>
                <h2>
                <b>Third Amego</b><br/><br/>
                    MIA
                </h2>
            </div>
        </span> */}
        <span>
            <div className='TeamLeft1'>
            <img src={StoryImg}/>
            </div>
            <div className='TeamRight1'>
                <h2 className='TeamText'>
                <b>Diego</b><br/><br/>
                    Diego is a digital artist & illustrator based in Barcelona, SPA. Fascinated with the digital
painting process his skills range from editorial illustration, character design, custom portraits,
movie posters and animation/motion graphics.
                </h2>
            </div>
        </span>
        <span>
            <div className='TeamLeft'>
            <img src={StoryImg}/>
            </div>
            <div className='TeamRight'>
                <h2>
                <b>Umesh aka Clueless_Nidget</b><br/><br/>
                    An Aerospace engineer who loves trekking and traveling, Umesh is a low gas fee smart contract expert, a blockchain enthusiast innovating the future today. He’s a forward thinking adventurer, a  detail obsessed programming professional. With the skills to bring the project to life, he deftly crafted the infrastructure for The Lost Dogs to stand on.
                </h2>
            </div>
        </span>
        </div>
    </div>
  )
}
//Name bold
//Umesh-MIA
//Left align