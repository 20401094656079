import React,{useEffect} from 'react'
import Video1 from "../assets/Poloriod.mp4";
import Video2 from "../assets/video.mp4";
import StoryImg from "../assets/Prom2.png";

export default function Marketing({myRef}) {

  return (
    <div className='Marketing'>
        <h1>Marketing Videos</h1>
        <span>
            <video src={Video1} muted autoPlay loop/>
            <img src={StoryImg}/>
            <video src={Video2} muted autoPlay loop/>
        </span>
    </div>
  )
}
