import React,{useState, useEffect} from 'react'
import Video from '../assets/hero1.mp4'
import "./style.css"

export default function Hero() {
  const [load, setLoad] = useState(false);
  
  return (
    <div className='Hero'>
      <video src={Video} disablePictureInPicture onLoad={()=>setLoad(true)} autoPlay loop muted/>
    </div>
  )
}
