import React,{useEffect} from 'react'
import StoryImg from "../assets/GhostStory.png";
import Aos from "aos";
import "aos/dist/aos.css"

export default function Story() {
  useEffect(()=>{
    Aos.init({duration:1000, offset:200})
  },[])
  return (
    <div className='Story' id="section1">
        <h1>Ghost Story</h1>
      <span>
      <div className='StoryRight'/>
        <div className='StoryLeft'>
        <p>
        “Among other things, you’ll find that you’re not the first person who was ever confused
        and frightened and even sickened by human behavior.”<br/>
        J.D Salinger<br/><br/>
        <b>Ghost</b> (<i>Noun</i>) An apparition of a dead person which is believed to appear or become manifest
        to the living, typically as a nebulous image.<br/><br/>
        It was a typical gray December day, the winds were whipping and there was a chill in the air.
        That was the day I found Ghost, a small white Pitbull, emaciated, scared, and abandoned on the
        mean streets of Chester, Pennsylvania. For anyone that is not familiar with Chester
        Pennsylvania, it’s a small intercity with a high drug and crime rate..<br/><br/>
        As I was starting my day, my friend stuck his head in my office and said, “I think there is a Pitbull
        mix dog running around the backlot.” Being curious and a lover of Pittys, I went to investigate.<br/><br/>
        When I first saw Ghost, she was trembling like a leaf in the cold winds. I’m not sure how she
        ended up like this, but it was clear she was in bad shape and needed help. In the light she
        looked like a ghost. After a few moments of gentle coaxing, I began to earn her trust. She
        started to warm up to me and even let me pet her. She began to wag her tail; I think she knew
        she was safe. If I had not intervened, I truly believed she would be a ghost, and the name stuck!<br/><br/>
        I want to share my experience and pay homage to my beautiful friend. This project was created
        to not only immortalize Ghost as an NFT, but to create awareness for all of our furry friends that
        need help. Furthermore, we are putting our money where our mouths are and donating 10% of
        the project to the ASPCA in Ghost’s Honor. That’s a real world NFT impact. Think of the animals
        that will be saved by this sizable donation.<br/><br/>
        Thank you so much for your support!
        </p>
        </div>
        <div className='StoryRight'>
          <img src={StoryImg}/>
        </div>
      </span>
    </div>
  )
}
