import React,{useEffect} from 'react'

export default function Message({myRef}) {

  return (
    <div className='Gallery' ref={myRef}>
      <span>
      <h1>Meta Message Board</h1>
      <h2>We want to create a place where our community can come together over their shared love of
      dogs. Each NFT hodler has a place to memorialize their dog(s) past or present; a place where
      they can run free forever</h2>
      {/* <h2>Coming Soon</h2> */}
      </span>
    </div>
  )
}
