import React from 'react'

export default function Seen() {
  return (
    <div className='Seen'>
      <span>
      <h1>As See On</h1>
      <div className='FootCal1' onClick={()=>window.open("https://nftcalendar.io/event/the-lost-dogs/")}/>
      <div className={'NftArt2'}/>
      </span>
    </div>
  )
}