import React,{useState} from 'react'
import { AiOutlineTwitter, AiFillInstagram, AiOutlineMenu, AiFillCaretUp, AiOutlineClose} from "react-icons/ai";
import { BsDiscord, BsTelegram } from "react-icons/bs";
import { ScrollingProvider, useScrollSection, useScrollSections,} from 'react-scroll-section';
import { SiLinktree } from "react-icons/si";
import Open1 from "../assets/open1.png";
import Open2 from "../assets/open2.png";
export default function NavBar({scrollTo, theme, setTheme}) {

  // const homeSection = useScrollSection('home');
  // const aboutSection = useScrollSection('about');
  const sections = useScrollSections();

  const [show, setShow] = useState(false)

  return (
    <div className={theme ? 'NavBar NavColor' : 'NavBar'}>
      <span>
        <div style={{ display: 'flex', alignItems:"center"}}>
        <div className={theme ?'NavCC NavCC1':'NavCC'} onClick={()=>scrollTo('h')}/>
        <div className='NavBoard'  onClick={()=>scrollTo('a')}/>
        {/* <div className='NavCal NavCalLeft' onClick={()=>window.open("https://nftcalendar.io/event/the-lost-dogs/")}/> */}
        </div>
        <div className={theme? 'Logo Logo1':'Logo'}/>
        <div className='SocialCon'>
        <AiOutlineTwitter style={{cursor:"pointer"}} color={theme? '#000':'#fff'} size={30} onClick={()=>window.open("https://twitter.com/TheLostDogsNFTS")}/>
        <AiFillInstagram style={{cursor:"pointer"}} color={theme? '#000':'#fff'} size={30} onClick={()=>window.open("https://www.instagram.com/thelostdogsnft/")}/>
        <BsTelegram style={{cursor:"pointer"}} color={theme? '#000':'#fff'} size={25} onClick={()=>window.open("https://t.me/TheLostDogs")}/>
        <BsDiscord style={{cursor:"pointer"}} color={theme? '#000':'#fff'} size={25} onClick={()=>window.open("https://discord.gg/uFWbzXeRrF")}/>
        <SiLinktree style={{cursor:"pointer"}} color={theme? '#000' : '#fff'} size={25} onClick={()=>window.open("https://linktr.ee/thelostdogs")}/>
        <label class="switch">
          <input type="checkbox" value={theme} onChange={(e)=>setTheme(!theme)}/>
          <span class="slider round">
            
          </span>
        </label>
        </div>
        <div className='MenuIconCon'>
        {/* <div className='NavCal NavCalRight' style={{marginRight:15}} onClick={()=>window.open("https://nftcalendar.io/event/the-lost-dogs/")}/> */}
        <AiOutlineMenu style={{cursor:"pointer"}} color={theme? '#000':'#fff'} size={30} onClick={()=>setShow(true)}/>
        </div>
        </span>
        <ul className='NavBarMenu'>
        {sections.map(({ id, onClick, selected }) => (
        <li key={id} onClick={onClick} style={{color: selected? "#bf1b25" : theme? '#000' : "#fff"}}>
          {id.toUpperCase()}
          <AiFillCaretUp className='ArrowIcon' color={selected? "#bf1b25" : "transparent"} size={25}/>
        </li>
          ))}
        </ul>
        <div className={show? 'MobileMenuCon MenuShow' : 'MobileMenuCon'}>
          <AiOutlineClose className='MenuClose' color={"#fff"} size={25} onClick={()=>setShow(false)}/>
          <ul className='NavBarMenu1'>
        {sections.map(({ id, onClick, selected }) => (
        <li key={id} onClick={onClick} style={{color: selected? "#000" : "#fff"}}>
          {id.toUpperCase()}
          <AiFillCaretUp className='ArrowIcon' color={selected? "#000" : "#bf1b25"} size={25}/>
        </li>
          ))}
        </ul>
        </div>
    </div>
  )
}
