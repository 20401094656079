import React from 'react'
import { AiOutlineTwitter, AiFillInstagram} from "react-icons/ai";
import { BsDiscord, BsTelegram } from "react-icons/bs";
import { SiLinktree } from "react-icons/si";

export default function Footer({theme}) {
  return (
    <div className={theme? 'Footer FootColor':'Footer'}>
      <div className='FootCal' onClick={()=>window.open("https://nftcalendar.io/event/the-lost-dogs/")}/>
        <div className='SocialCon1'>
        <AiOutlineTwitter style={{cursor:"pointer"}} color={theme? '#000' : '#fff'} size={30} onClick={()=>window.open("https://twitter.com/TheLostDogsNFTS")}/>
        <AiFillInstagram style={{cursor:"pointer"}} color={theme? '#000' : '#fff'} size={30} onClick={()=>window.open("https://www.instagram.com/thelostdogsnft/")}/>
        <BsTelegram style={{cursor:"pointer"}} color={theme? '#000' : '#fff'} size={25} onClick={()=>window.open("https://t.me/TheLostDogs")}/>
        <BsDiscord style={{cursor:"pointer"}} color={theme? '#000' : '#fff'} size={25} onClick={()=>window.open("https://discord.gg/uFWbzXeRrF")}/>
        <SiLinktree style={{cursor:"pointer"}} color={theme? '#000' : '#fff'} size={25} onClick={()=>window.open("https://linktr.ee/thelostdogs")}/>
        </div>
      <div className={theme? 'NftArt' : 'NftArt1'}/>
    </div>
  )
}
