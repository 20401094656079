import React from 'react'

export default function Merch() {
  return (
    <div className='Gallery'>
      <span>
      <h1>Merch Shop</h1>
      <h2>Why stop at displaying your The Lost Dogs online? Take the experience offline with The Lost Dogs branded merchandise. Including clothing displayed on Ghost, and even get your The Lost Dogs NFT on an official shirt (NFT Hodlers Only) </h2>
      </span>
    </div>
  )
}
