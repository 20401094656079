import React,{useEffect} from 'react'

export default function Reveal() {

  return (
    <div className='Gallery'>
      <span>
      <h1>Road Map Reveal</h1>
      <h2>Coming Soon</h2>
      </span>
    </div>
  )
}
