import React,{useRef, useState, useEffect} from 'react';
import {
  ScrollingProvider,
  Section,
} from 'react-scroll-section';

import './App.css';
import Club from './pages/club';
import Footer from './pages/footer';
import Gallery from './pages/gallery';
import Hero from './pages/hero';
import Marketing from './pages/marketing';
import Merch from './pages/merch';
import Message from './pages/message';
import Mint from './pages/mint';
import NavBar from './pages/navBar';
import Paper from './pages/paper';
import Reveal from './pages/reveal';
import RoadMap from './pages/roadMap';
import Seen from './pages/seen';
import Story from './pages/story';
import Team from './pages/team';

function App() {

  const head = useRef(null)
  const about = useRef(null)

  const onScroll=(val)=> {
    if (val === "h") {
      window.scrollTo({
        top: head.current.offsetTop,
        behavior: "smooth"
      })
    } else if (val === "a") {
      window.scrollTo({
        top: about.current.offsetTop,
        behavior: "smooth"
      })
    }
  }

  const [dark, setDark] = useState(false);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }
  
  useEffect(() => {
    window.addEventListener('resize', setDimension);
    
    return(() => {
        window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])
  
  return (
    <div className="App">
      {/* <NavBar scrollTo={onScroll} theme={dark} setTheme={setDark}/>
      <Hero/>
      <Story/>
      <Mint/>
      <Paper/>
      <RoadMap/>
      <Reveal/>
      <Team/>
      <Marketing/>
      <Gallery/>
      <Merch/>
      <Message myRef={about}/>
      <Club myRef={head}/>
      {screenSize.dynamicWidth<650?
      <Seen/>
      : null}
      <Footer theme={dark}/> */}
      <ScrollingProvider>
      <NavBar scrollTo={onScroll} theme={dark} setTheme={setDark}/>
      <Hero/>
      <Section id="Ghost Story">
      <Story/>
      </Section>
      <Section id="Find a Dog">
      <Mint/>
      </Section>
      <Section id="The Lost Paper">
      <Paper/>
      </Section>
      <Section id="Road Map Activations">
      <RoadMap/>
      </Section>
      <Section id="Road Map Reveal">
      <Reveal/>
      </Section>
      <Section id="Team">
      <Team/>
      </Section>
      <Marketing/>
      <Section id="Gallery">
      <Gallery/>
      </Section>
      <Section id='Merch'>
      <Merch/>
      </Section>
      <Message myRef={about}/>
      
      <Club myRef={head}/>
      {screenSize.dynamicWidth<650?
      <Section id='As See On'>
      <Seen/>
      </Section>
      : null}
      <Footer theme={dark}/>
      </ScrollingProvider>
    </div>
  );
}

export default App;