import React,{useEffect} from 'react'

export default function Gallery({myRef}) {
  return (
    <div className='Gallery' ref={myRef}>
      <span>
      <h1>Meta Gallery</h1>
      <h2>We believe fine art must be displayed! We are creating a Metaverse Gallery where art is
displayed. We have a wing where The Lost Dogs NFTs are proudly showcased.</h2>
      {/* <h2>Coming Soon</h2> */}
      </span>
    </div>
  )
}
